import React from 'react'

import Home from '@components/Home'
import { initSocket } from '@contexts/Socket'

const StreamPage = () => {
  console.log('STREAM PAGE')

  React.useEffect(() => {
    initSocket()
  }, [])

  return <Home hideFooter />
}

export default StreamPage
